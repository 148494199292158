import { Currency } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { DebounceSwapQuoteVariant, useDebounceSwapQuoteFlag } from 'featureFlags/flags/debounceSwapQuote'
import { useMemo } from 'react'
import { BN } from 'utils/bn'

import useDebounce from './useDebounce'
import { useV3TradePools } from './useV3SwapPools'
// Prevents excessive quote requests between keystrokes.
const DEBOUNCE_TIME = 350

// Temporary until we remove the feature flag.
const DEBOUNCE_TIME_INCREASED = 650

/**
 * Returns the best v2+v3 trade for a desired swap.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function usePositionTrade(
  currencyIn?: Currency,
  currencyOut?: Currency
): {
  isLoading: boolean
  pool?: Pool
  poolAddr?: string
} {
  const debouncedSwapQuoteFlagEnabled = useDebounceSwapQuoteFlag() === DebounceSwapQuoteVariant.Enabled
  const [debouncedCurrencyIn, debouncedCurrencyOut] = useDebounce(
    useMemo(() => [currencyIn, currencyOut], [currencyIn, currencyOut]),
    debouncedSwapQuoteFlagEnabled ? DEBOUNCE_TIME_INCREASED : DEBOUNCE_TIME
  )

  const { loading: TradePoolLoading, pool } = useQueryTradePool(debouncedCurrencyIn, debouncedCurrencyOut)

  return useMemo(
    () => ({
      isLoading: TradePoolLoading,
      ...pool,
    }),
    [TradePoolLoading, pool]
  )
}

function useQueryTradePool(
  currencyIn?: Currency,
  currencyOut?: Currency
): {
  loading: boolean
  pool?: {
    pool: Pool
    poolAddr: string
  }
} {
  const { pools, loading: poolsLoading } = useV3TradePools(currencyIn, currencyOut)

  const MaxLiquidityPool = useMemo(() => {
    if (!pools || pools.length === 0) return
    return pools.reduce(
      (pre: any, cur) => {
        const liquidity = BN(cur.pool.liquidity.toString())
        if (liquidity.gt(pre.maxLiquidity)) {
          pre.maxLiquidity = BN(cur.pool.liquidity.toString())
          pre.pool = cur
        }
        return pre
      },
      { maxLiquidity: BN(0), pool: undefined }
    )
  }, [pools])

  return useMemo(
    () => ({ loading: poolsLoading, pool: MaxLiquidityPool?.pool }),
    [MaxLiquidityPool?.pool, poolsLoading]
  )
}
