import { parseUnits } from '@ethersproject/units'
import { t } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useActiveChainId } from 'connection/useActiveChainId'
import JSBI from 'jsbi'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { useCallback, useMemo, useState } from 'react'

// try to parse a user entered amount for a given token
function tryParseAmount<T extends Currency>(value?: string, currency?: T): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export function useInputAmount(
  currency: Currency | null | undefined,
  otherBalance?: CurrencyAmount<Currency> | undefined
) {
  const [inputAmount, setInputAmount] = useState<{
    value: string
    amount?: CurrencyAmount<Currency>
  }>({
    value: '',
    amount: undefined,
  })

  const { account } = useActiveChainId()

  let balance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  balance = useMemo(() => (otherBalance ? otherBalance : balance), [balance, otherBalance])

  const [isInputValid, inputError] = useMemo(() => {
    const _amount = inputAmount.amount

    if (!balance || !_amount) {
      return [false, t`Enter an amount`]
    }

    const currency = _amount.currency

    if (!_amount.greaterThan(0) || _amount.greaterThan(balance.asFraction)) {
      return [false, t`Insufficient ${currency.symbol} balance`]
    }

    return [true, undefined]
  }, [balance, inputAmount.amount])

  const handleUserInput = useCallback(
    (_value: string) => {
      if (!currency) return
      const _amount = tryParseAmount(_value, currency)
      setInputAmount({
        value: _value,
        amount: _amount ? _amount : CurrencyAmount.fromRawAmount(currency, '0'),
      })
    },
    [currency]
  )

  const handleMax = useCallback((_amount: CurrencyAmount<Currency> | undefined) => {
    setInputAmount({
      value: _amount?.toExact() ?? '',
      amount: _amount,
    })
  }, [])

  const handleResetInput = useCallback(() => {
    setInputAmount({
      value: '',
      amount: undefined,
    })
  }, [])

  return useMemo(
    () => ({
      inputAmount,
      isInputValid,
      inputError,
      setInputAmount,
      handleUserInput,
      handleMax,
      handleResetInput,
      currency,
    }),
    [currency, handleMax, handleResetInput, handleUserInput, inputAmount, inputError, isInputValid]
  )
}
