import { useQuery } from '@apollo/client'
import { useActiveChainId } from 'connection/useActiveChainId'
import gql from 'graphql-tag'
import { useMemo } from 'react'

const useQueryVoteRewards = () => {
  const { account } = useActiveChainId()
  const query = gql`
    {
      userVoteds(where: { voter: "${account?.toLowerCase()}" }) {
        voter
        votedPool {
          lpAddr
          poolFee
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          hypervisor {
            depositedToken0Amount
            depositedToken1Amount
            depositedUSDAmount
          }
        }
        tokenId
        weight
      }
    }
  `
  const { data, loading: isLoading, error } = useQuery(query)

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
    }),
    [data, error, isLoading]
  )
}

export const useQueryProfileData = () => {
  const { data, isLoading, error } = useQueryVoteRewards()

  return useMemo(() => {
    if (error) return
    return { voteRewards: { data: data ? data : [], isLoading } }
  }, [data, error, isLoading])
}
