import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useActiveChainId } from 'connection/useActiveChainId'
import { keccak256, solidityPack } from 'ethers/lib/utils'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { PositionDetails } from 'types/position'
import { unwrappedToken } from 'utils/unwrappedToken'

import { useRoxPosnPool, useV3Factory, useV3NFTPositionManagerContract, useV3PoolContract } from './useContract'
const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

// compute current + counterfactual fees for a v3 position
export function useV3PositionFees(
  pool?: Pool,
  PositionDetails?: PositionDetails,
  asWETH = false
):
  | [
      CurrencyAmount<Currency>,
      CurrencyAmount<Currency>,
      CurrencyAmount<Currency>,
      CurrencyAmount<Currency>,
      CurrencyAmount<Currency>,
      CurrencyAmount<Currency>
    ]
  | [undefined, undefined, undefined, undefined, undefined, undefined] {
  const positionManager = useV3NFTPositionManagerContract(false)
  // const owner: string | undefined = useSingleCallResult(PositionDetails?.tokenId ? positionManager : null, 'ownerOf', [
  //   PositionDetails?.tokenId,
  // ]).result?.[0]

  const { account } = useActiveChainId()
  const factory = useV3Factory()

  const { result: rgpool } = useSingleCallResult(factory, 'getPool', [
    pool?.token0.address,
    pool?.token1.address,
    pool?.fee,
  ])

  const v3pool = useV3PoolContract(rgpool?.[0])
  const { result: PosnPool } = useSingleCallResult(v3pool, 'roxPosnPool')

  const gethash = (account: string, roxpool: any, long0: any) => {
    const hash2 = keccak256(solidityPack([`address`, `int24`, `int24`], [account, roxpool, long0]))
    return hash2
  }

  const PosnPoolContract = useRoxPosnPool(PosnPool?.[0])

  const parms = useMemo(() => {
    if (!PositionDetails || !account) return
    return gethash(account, PositionDetails.tickLower, PositionDetails.tickUpper)
  }, [PositionDetails, account])

  const { result: pendingFee } = useSingleCallResult(PosnPoolContract, 'pendingFee', [parms])

  const feeamount = useMemo(() => {
    if (!pendingFee) return
    return {
      perpFeeOwed0: pendingFee.perpFeeOwed0,
      perpFeeOwed1: pendingFee.perpFeeOwed1,
      spotFeeOwed0: pendingFee.spotFeeOwed0,
      spotFeeOwed1: pendingFee.spotFeeOwed1,
      totalFeeO: pendingFee.perpFeeOwed0.add(pendingFee.spotFeeOwed0),
      totalFee1: pendingFee.perpFeeOwed1.add(pendingFee.spotFeeOwed1),
    }
  }, [pendingFee])

  // we can't use multicall for this because we need to simulate the call from a specific address
  // latestBlockNumber is included to ensure data stays up-to-date every block

  if (pool && feeamount) {
    return [
      CurrencyAmount.fromRawAmount(asWETH ? pool.token0 : unwrappedToken(pool.token0), feeamount.totalFeeO.toString()),
      CurrencyAmount.fromRawAmount(asWETH ? pool.token1 : unwrappedToken(pool.token1), feeamount.totalFee1.toString()),
      CurrencyAmount.fromRawAmount(
        asWETH ? pool.token0 : unwrappedToken(pool.token0),
        feeamount.spotFeeOwed0.toString()
      ),
      CurrencyAmount.fromRawAmount(
        asWETH ? pool.token1 : unwrappedToken(pool.token1),
        feeamount.spotFeeOwed1.toString()
      ),
      CurrencyAmount.fromRawAmount(
        asWETH ? pool.token0 : unwrappedToken(pool.token0),
        feeamount.perpFeeOwed0.toString()
      ),
      CurrencyAmount.fromRawAmount(
        asWETH ? pool.token1 : unwrappedToken(pool.token1),
        feeamount.perpFeeOwed1.toString()
      ),
    ]
  } else {
    return [undefined, undefined, undefined, undefined, undefined, undefined]
  }
}
