import { useActiveChainId } from 'connection/useActiveChainId'
import { VESTAKELOCKTOKEN, VETOKEN } from 'constants/tokens'

export const useLockStakeToken = () => {
  const { chainId } = useActiveChainId()

  return chainId ? VESTAKELOCKTOKEN[chainId] : undefined
}

export const useLockVeToken = () => {
  const { chainId } = useActiveChainId()
  return chainId ? VETOKEN[chainId] : undefined
}
